.App {
  text-align: center;
  display: flex;
  background-color: #ECEAEA;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.creds_div{
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.options{
  width: 100%;
  height: 15%;
}
.op1{
  /* background-color: #F4D93B; */
  width: 50%;
  color: white;
  border: none;
  height: 100%;
  font-size: 20px;
  
}

input{
  width: 90%;
  height: 34px;
  border: none;
  outline: none;
  margin-top: 10px;
  color: #ECEAEA;
  font-size: 18px;
  background-color: transparent;

  border-bottom: solid 2px  #F4D93B;
}
.a{
  box-shadow: rgba(0, 0, 0, 0.25) -2px -4px 7px 2px inset  ;
}

*{
  overflow: hidden;
}

.creds{
  border-radius: 5px;
  background-color: #185685;
  height: 45vh;
  width: 40vw;
}

input::placeholder{
  color: rgba(245, 222, 179, 0.568);
}
.creds_img{
  width: fit-content;
  height: 2.5vw;
}

.op1_b{
  background-color: #F4D93B !important;
  width: 90%;
  height: 40px;
  transition-duration: 200ms;
  border-radius: 5px;
  box-shadow: 2px 2px 0.5px black;
}

.op1_b:hover{
  transform: translateY(-5px);
}

button{
  border: none;
  outline: none;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .creds{
    width: 90%;
    height: 50%;
    border-radius: 7px;
  }
  .App{
    height: 110vh;
    overflow-y: scroll !important;
  }
  .creds_img{
    margin: 10px;
    width: 50vw;
    height: fit-content;
  }
}